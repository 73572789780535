import services from '@/services';
import {
  isOpenLRModalState,
  LRModalInitalPage,
  showMoneyDialogState,
} from '@/stores/auth';
import { weekCardActivityState } from '@/stores/config';
import { msgState } from '@/stores/msg';
import { ActivityType } from '@/types';
import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import useAuth from './useAuth';

const useHeartbeat = (interval: number) => {
  const { logout, updateWallet } = useAuth();
  const setInitalPage = useSetRecoilState(LRModalInitalPage);
  const setOpenLRModal = useSetRecoilState(isOpenLRModalState);
  const setAWeekCards = useSetRecoilState(weekCardActivityState);
  const setShowMoneyDialog = useSetRecoilState(showMoneyDialogState);

  const setMsg = useSetRecoilState(msgState);
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const heartbeat = () => {
      services.config.heartbeat().then(async (result) => {
        if (!result) {
          return;
        }
        const { handle, data } = result;
        const message = data?.message;

        if (message?.showType === 2 && message?.showLevel === 3) {
          let moneyMsg = { ...message, open: true };
          setShowMoneyDialog(moneyMsg);
          const weekRes = await services.config.getActivityListV2(
            ActivityType.周卡,
          );
          setAWeekCards(weekRes || []);
        }

        if (handle === 'clean_token') {
          logout();

          timer.current && clearInterval(timer.current);

          setInitalPage(0);
          setOpenLRModal(true);
        } else {
          // 更新钱包
          if (data) {
            updateWallet(data);
          }
          // 处理消息
          if (message) {
            setMsg({ message });
          }

          // // mock
          // setMsg({
          //   message: {
          //     contents: {
          //       enUS: '1***42 received R$1000000', //站内信消息内容语言包-英文
          //       ptBR: '1***42 recebeu R$1000000', //站内信消息内容语言包-葡文
          //       zhCN: '1***42 收到 R$1000000', //站内信消息内容语言包-中文
          //     }, //站内信消息内容语言包
          //     pic: 'https://www.br-baby.com/api/uploads/avatar.png', //展示图片
          //     routePath: '/commissions', //跳转路由
          //     receiverType: 5,
          //     showLevel: 2, //展示等级：1、首页轮播；2、全局轮播；3、当前页弹窗展示
          //   },
          // });
        }
      });
    };

    timer.current = setInterval(heartbeat, interval);
    heartbeat();

    return () => {
      timer.current && clearInterval(timer.current);
    };
  }, []);
};

export default useHeartbeat;
