import { authPages } from '@/routers/config';
import { isOpenDepositState, isOpenRedeeDialogState } from '@/stores/auth';
import { homeTab, isShowHomeGuide } from '@/stores/config';
import { HomeTab } from '@/types';
import { IBannerItem } from '@/types/config';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import useAuth from './useAuth';
import useResponsive from './useResponsive';

const isNeedCheckLogin = (path: string) => {
  return authPages.includes(path);
};

export const useAuthJump = () => {
  const { isLogin, openLogin } = useAuth();
  const isMobile = useResponsive('down', 'md');
  const navigate = useNavigate();
  const setIsShowDLGuide = useSetRecoilState(isShowHomeGuide);
  const setHomeTab = useSetRecoilState(homeTab);
  const openDiposit = useSetRecoilState(isOpenDepositState);
  const setIsOpenRedeeDialog = useSetRecoilState(isOpenRedeeDialogState);

  /**
   * 登录权限跳转，如果需要登录则跳转到登录页
   * @param data 数据
   * @returns {boolean} 是否跳转成功（用于某些弹窗是否关闭，理论上需要登录的页面，都不需要关闭弹窗）
   */
  const authJump = (data?: IBannerItem | null): boolean => {
    if (!data || !data?.url) {
      return false;
    }

    const isNeedLogin = isNeedCheckLogin(data.url);
    if (isNeedLogin && !isLogin) {
      openLogin();
      return false;
    }

    if (data.url.startsWith('http')) {
      window.open(data.url, '_blank');
      return true;
    }

    if (data.target === 1) {
      window.open(data.url, '_blank');
      return true;
    }

    if (data.url.startsWith('/deposit')) {
      openDiposit(true);
      return true;
    }

    if (isMobile) {
      if (data.url.includes('/lucky')) {
        setIsShowDLGuide(true);
        setHomeTab(HomeTab.Spinner);
      } else if (data.url.includes('/commissions')) {
        setIsShowDLGuide(true);
        setHomeTab(HomeTab.Invite);
      } else if (data.url.includes('/vip')) {
        setIsShowDLGuide(true);
        setHomeTab(HomeTab.Vip);
      } else if (data.url.includes('/me')) {
        setIsShowDLGuide(true);
        setHomeTab(HomeTab.Me);
      } else {
        navigate(data?.url);
      }
    } else if (data.url.startsWith('/coupon')) {
      setIsOpenRedeeDialog(true);
      return true;
    } else {
      navigate(data.url);
    }

    return true;
  };

  return {
    authJump,
  };
};
