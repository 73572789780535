import Center from '@/components/center';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { agentLevelState } from '@/stores/auth';
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useRecoilState } from 'recoil';
import IMAGE_FLOW from './images/invite-flow.png';
import IMAGE_FLOW2 from './images/invite-flow2.png';

interface Props {}

const borderColor = 'rgba(255, 255, 255, 0.15)';
const styles = {
  commissionBg: {
    borderRight: `1px solid ${borderColor}`,
    px: '6px',
    py: '6px',
  },
};

const CommissionsRules: React.FC<Props> = (props: Props) => {
  const isMobile = useResponsive('down', 'md');
  return isMobile ? <CommissionsRulesMoblie /> : <CommissionsRulesWeb />;
};

const CommissionsRulesWeb = () => {
  const { __ } = useLang();
  const [agentLevelList, setAgentLevelList] = useRecoilState(agentLevelState);
  const minMoney =
    agentLevelList?.length > 0 ? agentLevelList[0].rentou_money : 0;
  const maxMoney =
    agentLevelList?.length > 1
      ? agentLevelList[agentLevelList.length - 1].rentou_money
      : 0;
  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" height="48px">
        <Typography variant="subtitle2" fontWeight={700}>
          {__('Rule description')}
        </Typography>
      </Box>
      <Stack
        borderRadius="8px"
        bgcolor="card.main"
        p={3}
        spacing={3}
        flexWrap={'wrap'}
      >
        <Box flex={1}>
          <img
            alt=""
            src={IMAGE_FLOW2}
            width={564}
            height={424}
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
          <Typography mt={3} variant="body2" color="text.secondary">
            {__('commission tip1')
              .replace('[min]', minMoney)
              .replace('[max]', maxMoney)}
            <br />
            {__('commission tip2')}
            <br />
            {__('commission tip3')}
          </Typography>
        </Box>
        <Box flex={1}>
          {/* <TableContainer
            sx={{
              borderRadius: '8px',
              overflowX: 'hidden',
              border: '1px solid #272142',
            }}
          >
            <CommissionsRulesTable />
          </TableContainer> */}
          <CommissionsRulesTable2 ruleList={agentLevelList} />
        </Box>
      </Stack>

      <Box mt={2}>{/*<HomeRankList type={RankType.Commission} />*/}</Box>
    </Box>
  );
};

const CommissionsRulesMoblie: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  const [agentLevelList, setAgentLevelList] = useRecoilState(agentLevelState);
  const minMoney =
    agentLevelList?.length > 0 ? agentLevelList[0].rentou_money : 0;
  const maxMoney =
    agentLevelList?.length > 1
      ? agentLevelList[agentLevelList.length - 1].rentou_money
      : 0;
  return (
    <>
      <Box mt={2} display="flex" alignItems="center" height="48px">
        <Typography variant="subtitle2" fontWeight={700}>
          {__('Rule description')}
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor: 'card.main',
          px: 2,
          py: 3,
          borderRadius: 1,
        }}
      >
        <Stack spacing={2}>
          <img
            alt=""
            src={IMAGE_FLOW}
            width={311}
            height={208}
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
          <Typography mt={2.5} variant="body2" color="#8B899C">
            {__('commission tip1')
              .replace('[min]', minMoney)
              .replace('[max]', maxMoney)}
            {/* <br /> */}
            {/* {__('commission tip1-0')}
            <br />
            {__('commission tip1-1')}
            <br />
            {__('commission tip1-2')} */}
            <br />
            {__('commission tip2')}
            <br />
            {__('commission tip3')}
          </Typography>
          <CommissionsRulesTable2 ruleList={agentLevelList} />
          {/* <TableContainer
            sx={{ mt: 3, overflowX: 'hidden', border: '1px solid #272142' }}
            component={Paper}
          >
            <CommissionsRulesTable />
          </TableContainer> */}
        </Stack>
      </Box>

      <Box mt={2}>{/*<HomeRankList type={RankType.Commission} />*/}</Box>
    </>
  );
};

export const CommissionsRulesTable2 = (props: { ruleList: Array<any> }) => {
  const { ruleList = [] } = props;
  const { __ } = useLang();
  return (
    <Box textAlign="center">
      <Box
        sx={{
          display: 'flex',
          bgcolor: '#313D4D',
          borderRadius: '8px 8px 0 0',
          borderTop: '1px solid rgba(255, 255, 255, 0.15)',
        }}
      >
        <Center
          flexBasis={{ xs: '25%', md: '15%' }}
          sx={{
            borderRight: '1px solid rgba(255, 255, 255, 0.15)',
            borderLeft: '1px solid rgba(255, 255, 255, 0.15)',
            borderTopLeftRadius: '8px',
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {__('Level')}
          </Typography>
        </Center>
        <Center
          flexBasis={{ xs: '25%', md: '15%' }}
          sx={{ borderRight: '1px solid rgba(255, 255, 255, 0.15)' }}
        >
          <Typography variant="body2" color="text.secondary">
            {__('Depositor')}
          </Typography>
        </Center>
        <Box flexBasis={{ xs: '50%', md: '70%' }}>
          <Center
            height="40px"
            sx={{
              borderBottom: '1px solid rgba(255, 255, 255, 0.15)',
              borderRight: '1px solid rgba(255, 255, 255, 0.15)',
              borderTopRightRadius: '8px',
            }}
          >
            <Typography variant="body2" color="text.secondary">
              {__('Depositor')}
            </Typography>
          </Center>
          <Box display="flex">
            <Center
              height="40px"
              flexBasis="33.33%"
              sx={{
                borderRight: '1px solid rgba(255, 255, 255, 0.15)',
              }}
            >
              <Typography variant="body2" color="text.secondary">
                {__('First')}
              </Typography>
            </Center>
            <Center
              height="40px"
              flexBasis="33.33%"
              sx={{ borderRight: '1px solid rgba(255, 255, 255, 0.15)' }}
            >
              <Typography variant="body2" color="text.secondary">
                {__('Second')}
              </Typography>
            </Center>
            <Center
              height="40px"
              flexBasis="33.33%"
              sx={{ borderRight: '1px solid rgba(255, 255, 255, 0.15)' }}
            >
              <Typography variant="body2" color="text.secondary">
                {__('Third')}
              </Typography>
            </Center>
          </Box>
        </Box>
      </Box>
      {ruleList.map((item, idx) => {
        const islast = idx === ruleList.length - 1;
        return <TableItem key={idx} data={item} islast={islast} />;
      })}
    </Box>
  );
};

const TableItem = (props: { islast?: boolean; data?: any }) => {
  const { data } = props;
  const { commission = {} } = data;
  return (
    <Box
      sx={{
        display: 'flex',
        borderBottom: `1px solid ${borderColor}`,
        borderBottomLeftRadius: props.islast ? '8px' : 0,
        borderBottomRightRadius: props.islast ? '8px' : 0,
      }}
    >
      <Center
        flexBasis={{ xs: '25%', md: '15%' }}
        sx={{
          borderRight: `1px solid ${borderColor}`,
          borderLeft: `1px solid ${borderColor}`,
          borderBottomLeftRadius: props.islast ? '8px' : 0,
        }}
      >
        <Typography variant="body1" color="text.main">
          <img src={data.pic} alt="" width={32} height={16} />
        </Typography>
      </Center>
      <Center
        flexBasis={{ xs: '25%', md: '15%' }}
        sx={{ borderRight: `1px solid ${borderColor}` }}
      >
        <Typography variant="body1" color="text.main">
          {data.update_user_num}
        </Typography>
      </Center>
      <Box flexBasis={{ xs: '50%', md: '70%' }}>
        <Box display="flex">
          <Center height="50px" flexBasis="33.33%" sx={styles.commissionBg}>
            <Typography
              variant="body1"
              color="text.main"
              sx={{ wordBreak: 'break-word' }}
              width={'100%'}
            >
              {commission['1'] || ''}
            </Typography>
          </Center>
          <Center height="50px" flexBasis="33.33%" sx={styles.commissionBg}>
            <Typography
              variant="body1"
              color="text.main"
              sx={{ wordBreak: 'break-word' }}
            >
              {commission['2'] || ''}
            </Typography>
          </Center>
          <Center
            height="50px"
            flexBasis="33.33%"
            sx={{
              ...styles.commissionBg,
              borderBottomRightRadius: props.islast ? '8px' : 0,
            }}
          >
            <Typography
              variant="body1"
              color="text.main"
              sx={{ wordBreak: 'break-word' }}
            >
              {commission['3'] || ''}
            </Typography>
          </Center>
        </Box>
      </Box>
    </Box>
  );
};

const CommissionsRulesTable = () => {
  const { __ } = useLang();
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              color: '#8B899C',
              bgcolor: 'background.paper',
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            {__('Example')}
          </TableCell>
          <TableCell
            sx={{
              color: '#8B899C',
              bgcolor: 'background.paper',
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            {__('Bets')}
          </TableCell>
          <TableCell
            sx={{
              color: '#8B899C',
              bgcolor: 'background.paper',
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            {__('Proportion')}
          </TableCell>
          <TableCell
            sx={{
              bgcolor: 'background.paper',
              color: '#8B899C',
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            {__('Earn')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell
            sx={{
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            A1+B1
          </TableCell>
          <TableCell
            sx={{
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            100,000
          </TableCell>
          <TableCell
            sx={{
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            0.3%
          </TableCell>
          <TableCell
            sx={{
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            R$300
          </TableCell>
        </TableRow>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell
            sx={{
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
            }}
          >
            A21+A22+B21+B22
          </TableCell>
          <TableCell
            sx={{
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            200,000
          </TableCell>
          <TableCell
            sx={{
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            0.2%
          </TableCell>
          <TableCell
            sx={{
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            R$300
          </TableCell>
        </TableRow>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell
            sx={{
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
            }}
          >
            A31+A32+B31+B32
          </TableCell>
          <TableCell
            sx={{
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            200,000
          </TableCell>
          <TableCell
            sx={{
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            0.1%
          </TableCell>
          <TableCell
            sx={{
              borderColor: '#272142',
              width: '25%',
              minWidth: '25%',
              px: 1,
              maxWidth: '25%',
            }}
          >
            R$300
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default CommissionsRules;
