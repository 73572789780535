export enum LanguageType {
  EN = 'en',
  PT = 'pt',
}

export enum HomeTab {
  Spinner = 'Spinner',
  Invite = 'Invite',
  Home = 'Home',
  Vip = 'Vip',
  Me = 'Me',
  Bonus = 'Bonus',
}

export enum AccountTabType {
  General = 'General',
  Security = 'Security',
  Address = 'Address',
  Suspension = 'Suspension',
}

export enum TransationsTabType {
  Transactions = 'Transactions',
  Deposists = 'Deposists',
  Withdraws = 'Withdraws',
}

export enum SpinnerTabType {
  Daily = 'VIP Daily Spin',
  Progress = ' Vip Progress Spin',
}

export enum SigninType {
  Phone = 'Phone',
  Email = 'Email',
}

// 1、签到活动；2、红包/兑换码活动；3、首充活动；4、单次充值活动；5、累计充值活动；6、邀新活动；7、打码量活动；8、排行榜互动
export enum ActivityType {
  签到活动 = 1,
  红包兑换码活动 = 2,
  首充活动 = 3,
  单次充值活动 = 4,
  累计充值活动 = 5,
  邀新活动 = 6,
  打码量活动 = 7,
  排行榜互动 = 8,
  周卡 = 9,
}

export enum RewardType {
  邀新奖励 = 0,
  充值奖励 = 1,
  首充奖励 = 2,
  普通充值 = 3,
}

// 邀新活动汇总信息
export interface InviteEventsInfoDto {
  /**
   * 有效用户判定-充值金额
   */
  vaildInviteeAmt: number;
  /**
   * 有效用户判定-下单金额
   */
  vaildInviteeOrderAmt: number;
  /**
   * 已领取邀新奖励金额
   */
  inviteRewardAmt: number;
  /**
   * 有效受邀请人数
   */
  vaildInviteeCount: number;
  /**
   * 受邀请人数
   */
  inviteeCount: number;
}

/**
 * 用户信息
 */
export interface IUserInfo {
  name: string;
  vipState: number;
  agent: number;
  agent_url: string;
  agent_money: number;
  avatar: string;
  birthday_day: string;
  email_bind: number;
  email: string;
  exp: number;
  level: number;
  level_end_date: number;
  level_name: string;
  level_pic: string;
  level_progress: number;
  login_ip: string;
  login_time: number;
  lottery_num: number;
  max_exp: number;
  money: number;
  msg: number;
  nick_name: string;
  no_dama_money: number;
  pay_password: number;
  phone: string;
  phone_bind: number;
  qiandao: number;
  qiandao_num: number;
  sex: number;
  token: string;
  uid: number;
  username: string;
  rentou_money: number;
  total_agent_money: number;
  total_rentou_money: number;
  protect_level_dama: number;
  protect_level_money: number;
  protect_level_money_num: number;
  oauth1_banding_status: number;
  oauth2_banding_status: number;
  cpfCode: string;
  cpfId: string;
  cpfState: number;
  vipExpireTime: number;
  agentExpireTime: number;
  cpfType: number;
}

export interface IBanner {
  title: string;
  description: string;
  image_url: string;
  href: string;
  action: string;
  action_text: string;
  mobile_image_url: string;
  os_type: number;
  in_dialog: number;
}

export interface IGameSearchItem {
  demo_disabled: boolean;
  game_provider_id: number;
  id: number;
  image_url: string;
  name: string;
  slug: string;
}

export interface IGame {
  id: number;
  name: string;
  image_url: string;
  slug: string;
  game_provider_id: number;
  demo_disabled: boolean;
  provider: {
    slug: string;
    name: string;
  };
}

export interface IGameProvider {
  game_count: number;
  id: number;
  image_url: string;
  name: string;
  slug: string;
}

export interface ScrollMessageDto {
  receivedAmt: number;
  avatar: string;
  userId: string;
  desc: I18nValue;
}

export interface I18nValue {
  enUS: string;
  ptBR: string;
  zhCN: string;
}

export interface SupportItemDto {
  code: string;
  id: string;
  name: string;
  qr: string;
  qrUrl: string;
  showType: number;
  type: number;
  typeName: string;
  url: string;
}

// 1、首页轮播；2、全局轮播；3、当前页弹窗展示
export enum MessageShowLevel {
  首页轮播 = 1,
  全局轮播 = 2,
  当前页弹窗展示 = 3,
}

export interface HeartbeatMessageDto {
  contents: I18nValue;
  pic?: string;
  routePath?: string;
  receiverType: number;
  showLevel: MessageShowLevel;
  showType?: number;
}

export interface AgentSupportDto {
  code: string;
  id: string;
  name: string;
  qr: string;
  qrUrl: string;
  typeName: string;
  url: string;
  showType: number;
  type: number;
}