import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import bannerImage from '@/assets/images/home-banner.png';
import bannerImage1 from '@/assets/images/invite_banner.png';
import { useAuthJump } from '@/hooks/useAuthJump';
import useResponsive from '@/hooks/useResponsive';
import { bannerList } from '@/stores/config';
import { globalMaxWidth } from '@/theme';
import { IBannerItem } from '@/types/config';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Autoplay, EffectCoverflow, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

interface Props {}

const HomeBanner: React.FC<Props> = (props: Props) => {
  const isMobile = useResponsive('down', 'md');
  const bannerListValue = useRecoilValue(bannerList);

  // useEffect(() => {
  //   const fetchBanner = async () => {
  //     setLoading.on();
  //     try {
  //       const { data } = await services.config.getBanner({
  //         page: 1,
  //         pageSize: 20,
  //         lang: "en",
  //       });
  //       if (Array.isArray(data?.banners)) {
  //         setBanners(data.banners);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setBanners(MOCK_BANNERS.banners);
  //     } finally {
  //       setLoading.off();
  //     }
  //   };
  //   fetchBanner();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // if (loading) {
  //   return (
  //     <Center mx={{ xs: 0, md: -5 }}>
  //       {isMobile ? (
  //         <Skeleton variant="rounded" width="100%" height="188px"></Skeleton>
  //       ) : (
  //         <Skeleton
  //           variant="rectangular"
  //           width="100vw"
  //           height="24vw"
  //         ></Skeleton>
  //       )}
  //     </Center>
  //   );
  // }

  const height = isMobile ? '188px' : '24vw';

  const handleClick = (data: IBannerItem) => {
    if (!data.url) {
      return;
    }

    if (data.target === 1) {
      window.open(data.url, '_blank');
    } else {
      window.location.href = data.url;
    }
  };

  return (
    <Box mx={{ xs: 0, md: -5 }} my={2}>
      {/* <Swiper
        pagination={pagination}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        autoplay={{ delay: 5000 }}
        loop
      > */}
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={1.5}
        coverflowEffect={{
          rotate: 0,
          depth: 40,
          modifier: 1,
          stretch: 20,
          slideShadows: true,
        }}
        autoplay={{
          delay: 2000,
          // disableOnInteraction: false,
          reverseDirection: false,
        }}
        loop
        pagination={true}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper"
      >
        {[...bannerListValue, ...bannerListValue, ...bannerListValue].map(
          (banner, index) => {
            return (
              <SwiperSlide key={`${banner.title}-${index}`}>
                <Box
                  sx={{
                    borderRadius: '10px',
                    height: height,
                    overflow: 'hidden',
                  }}
                  onClick={() => handleClick(banner)}
                >
                  <img
                    style={{
                      width: '75vw',
                      height: height,
                      borderRadius: isMobile ? '10px' : '0',
                      objectFit: 'fill',
                    }}
                    src={index % 2 === 1 ? bannerImage : bannerImage1}
                    alt={banner.title}
                  />
                </Box>
              </SwiperSlide>
            );
          },
        )}
      </Swiper>
    </Box>
  );
};

const HomeBannerContent = () => {
  const [mySwiper, setMySwiper] = useState<any>();
  const bannerListValue = useRecoilValue(bannerList);
  const { authJump } = useAuthJump();

  const width = Math.min(globalMaxWidth, window.innerWidth);

  useEffect(() => {
    if (!mySwiper) {
      return;
    }

    const interval = setInterval(() => {
      try {
        if (mySwiper?.isEnd) {
          mySwiper?.slideTo(0);
        } else {
          mySwiper?.slideNext();
        }
      } catch (error) {
        console.log(error);
      }
    }, 5000);
    return () => {
      interval && clearInterval(interval);
    };
  }, [mySwiper]);

  const handleClick = (data: IBannerItem) => {
    authJump(data);
  };

  return (
    <Box>
      <Swiper
        pagination={true}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        // autoplay={{ delay: 5000 }}
        onSwiper={setMySwiper}
        loop
        breakpoints={{
          100: {
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            // autoplay: true,
          },
          // 600: {
          //   slidesPerView: 2,
          //   spaceBetween: 10,
          //   loop: true,
          //   // autoplay: true,
          // },
        }}
      >
        {bannerListValue.map((banner, index) => {
          return (
            <SwiperSlide key={`${banner.title}-${index}`}>
              <Box
                sx={{
                  width: width - 32,
                  height: (width - 32) / 2,
                }}
              >
                <img
                  style={{
                    width: '100%',
                    maxWidth: globalMaxWidth - 32,
                    height: '100%',
                    borderRadius: '10px',
                    objectFit: 'fill',
                  }}
                  onClick={() => handleClick(banner)}
                  src={banner.pic}
                  // srcSet={`
                  //   ${banner.pic} 300w,
                  //   ${banner.mobile_image_url} 600w,
                  //   ${banner.mobile_image_url},
                  //   `}
                  alt={banner.title}
                />
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};

export default HomeBannerContent;
