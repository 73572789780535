import IMAGE_ADD from '@/assets/topnav/icon_add.svg';
import IMAGE_COIN from '@/assets/topnav/icon_money.svg';
import Center from '@/components/center';
import useAuth from '@/hooks/useAuth';
import { isOpenDepositState } from '@/stores/auth';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
// import { CountUp } from 'countup.js';
import { useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import IcomImage from '../IconImage';

import AUDIO_CASH from '@/assets/audios/cash.mp3';
import { playAudio } from '@/utils/speak';

interface Props {}

const BalanceBar: React.FC<Props> = (props: Props) => {
  const { wallet, getWallet } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const openDiposit = useSetRecoilState(isOpenDepositState);
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (wallet?.money) {
      const money = +(+wallet.money).toFixed(2);
      initCountUp(money);
      playAudio(AUDIO_CASH);
    }
  }, [wallet?.money]);

  async function initCountUp(money: number) {
    if (!ref.current) {
      return;
    }

    const countUpModule = await import('countup.js');
    const countUpAnim = new countUpModule.CountUp(ref.current, money, {
      decimal: '.',
      decimalPlaces: 2,
      duration: 1,
    });
    if (!countUpAnim.error) {
      countUpAnim.start();
    } else {
      console.error(countUpAnim.error);
    }
  }

  const handleRefresh = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    await getWallet();
    setIsLoading(false);
  };

  const handleClickDeposit = () => {
    // setIpLock(true);
    openDiposit(true);
  };

  return (
    <Center
      sx={{
        bgcolor: '#313D4D',
        height: '32px',
        borderRadius: '8px',
        pl: 1,
        pr: 0.5,
        cursor: 'pointer',
      }}
      onClick={handleRefresh}
    >
      <Stack direction="row" alignItems="center" spacing="4px">
        {isLoading ? (
          <CircularProgress size={12} color="info" />
        ) : (
          <IcomImage src={IMAGE_COIN} alt="coin" />
        )}
        <Typography ref={ref} variant="body1" mx={0.5}>
          0.00
        </Typography>
        <Box
          sx={{
            width: '24px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            minWidth: '24px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleClickDeposit();
          }}
        >
          <img
            src={IMAGE_ADD}
            alt=""
            style={{ width: '24px', height: '24px', minWidth: '24px' }}
          />
        </Box>
      </Stack>
    </Center>
  );
};

export default BalanceBar;
