import { FirstDepositDto } from '@/hooks/useFirstDepositBouns';
import { AgentSupportDto, HomeTab, SupportItemDto } from '@/types';
import {
  IBannerItem,
  IGameCategory,
  IGameItem,
  IGamesMap,
  IIndexCategory,
  IPlatform,
} from '@/types/config';
import { atom } from 'recoil';

export const configData = atom({
  key: 'configData',
  default: false,
});

export const isDataLoadedState = atom({
  key: 'isDataLoadedState',
  default: false,
});

export const gameListRecommend = atom<IGameItem[]>({
  key: 'gameListRecommend',
  default: [],
});

export const gameListRecently = atom<IGameItem[]>({
  key: 'gameListRecently',
  default: [],
});

export const gameCategoryList = atom<IGameCategory[]>({
  key: 'gameCategoryList',
  default: [],
});

export const bannerList = atom<IBannerItem[]>({
  key: 'bannerList',
  default: [],
});

export const indexCategoryList = atom<IIndexCategory[]>({
  key: 'indexCategoryList',
  default: [],
});

export const allGamesMap = atom<IGamesMap>({
  key: 'allGamesMap',
  default: {},
});

export const allGamesList = atom<IGameItem[]>({
  key: 'allGamesList',
  default: [],
});

export const plateformState = atom<IPlatform[]>({
  key: 'plateformState',
  default: [],
});

export const minTiXianState = atom<number>({
  key: 'minTiXianState',
  default: 0,
});

export const diaLogBannerList = atom<IBannerItem[]>({
  key: 'diaLogBannerList',
  default: [],
});

export const isShowDaLogBanner = atom<boolean>({
  key: 'isShowDaLogBanner',
  default: false,
});

export const supportListState = atom<Array<SupportItemDto>>({
  key: 'supportListState',
  default: [],
});

export const isShowHomeGuide = atom<boolean>({
  key: 'isShowHomeGuide',
  default: false,
});

export const homeTab = atom<HomeTab>({
  key: 'homeTab',
  default: HomeTab.Home,
});

export const payChannelDataState = atom<any>({
  key: 'payChannelDataState',
  default: {},
});

export const tenantConfigState = atom<any>({
  key: 'tenantConfigState',
  default: {},
});

export const depositActivityState = atom<FirstDepositDto[]>({
  key: 'depositActivityState',
  default: [],
});

export const bottomSupportList = atom<any[]>({
  key: 'bottomSupportList',
  default: [],
});

export const prominentSupportList = atom<SupportItemDto[]>({
  key: 'prominentSupportList',
  default: [],
});

export const weekCardActivityState = atom<any[]>({
  key: 'weekCardActivityState',
  default: [],
});

export const incognitoModeIPState = atom<string>({
  key: 'incognitoModeIPState',
  default: '',
});

export const isIpLockState = atom<boolean>({
  key: 'isIpLockState',
  default: false,
});

export const noticeDialogState = atom<{
  open: boolean;
  title: string;
  content: string;
}>({
  key: 'noticeDialogState',
  default: {
    open: false,
    title: '',
    content: '',
  },
});

export const promotionListState = atom<any[]>({
  key: 'promotionListState',
  default: [],
});

export const agentSupportState = atom<AgentSupportDto[]>({
  key: 'agentSupportState',
  default: [],
});
