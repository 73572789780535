import useScrollToTop from '@/hooks/useScrollToTop';
import { homeTab } from '@/stores/config';
import { HomeTab } from '@/types';
import { Box, Fade } from '@mui/material';
import { useRecoilValue } from 'recoil';
import CommissionsPage from '../Commissions';
import MePage from '../Me';
import PromotionsPage from '../Promotions';
import IndexPage from '../index';

const Home = () => {
  const tab = useRecoilValue(homeTab);
  useScrollToTop();
  // const isMobile = useResponsive('down', 'md');

  // if (!isMobile) {
  //   return <IndexPage />;
  // }

  return (
    <Box>
      <Fade in={tab === HomeTab.Home}>
        <Box overflow="hidden" height={tab === HomeTab.Home ? 'auto' : 0}>
          <IndexPage />
        </Box>
      </Fade>
      <Fade in={tab === HomeTab.Invite}>
        <Box overflow="hidden" height={tab === HomeTab.Invite ? 'auto' : 0}>
          <CommissionsPage />
        </Box>
      </Fade>
      {/* <Fade in={tab === HomeTab.Spinner}>
        <Box overflow="hidden" height={tab === HomeTab.Spinner ? 'auto' : 0}>
          <BlindBox />
        </Box>
      </Fade> */}
      {/* <Fade in={tab === HomeTab.Vip}>
        <Box overflow="hidden" height={tab === HomeTab.Vip ? 'auto' : 0}>
          <VipPage />
        </Box>
      </Fade> */}
      <Fade in={tab === HomeTab.Me}>
        <Box overflow="hidden" height={tab === HomeTab.Me ? 'auto' : 0}>
          <MePage />
        </Box>
      </Fade>
      <Fade in={tab === HomeTab.Bonus}>
        <Box overflow="hidden" height={tab === HomeTab.Bonus ? 'auto' : 0}>
          <PromotionsPage />
        </Box>
      </Fade>
    </Box>
  );
};

export default Home;
