import { homeTab } from '@/stores/config';
import { HomeTab } from '@/types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useOffSetTop from './useOffSetTop';

const useScrollToTop = () => {
  useEffect(() => {
    const root = document.getElementById('root');
    root?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
};

export const useHomeTabScrollToTop = () => {
  const homeTabValue = useRecoilValue(homeTab);

  useEffect(() => {
    const root = document.getElementById('root');

    root?.scrollTo({
      top: 0,
      left: 0,
    });

    if (homeTabValue === HomeTab.Invite) {
      const scrollContainer = document.getElementById(
        'invite-scroll-container',
      );
      scrollContainer?.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [homeTabValue]);
};

export const useHeaderTransparentBackground = () => {
  const homeTabValue = useRecoilValue(homeTab);
  const isOffset = useOffSetTop(5);
  const location = useLocation();

  const isHomeInvite =
    location.pathname === '/' && homeTabValue === HomeTab.Invite;
  const isLucky = location.pathname === '/lucky';
  const isGameInfo = location.pathname === '/game/info';
  const isCoupon = location.pathname === '/coupon';

  const isNeedTransparentBackground =
    isHomeInvite || isLucky || isGameInfo || isCoupon;

  return {
    isBgTransparent: isNeedTransparentBackground ? !isOffset : false,
  };
};

export default useScrollToTop;
