import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import { ActivityDto, BoxStatus } from '@/hooks/useActivity';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import {
  BoxRewardDialog,
  InviteBoxList,
  InviteRules,
} from '@/pages/InviteBoxPage/components';
import services from '@/services';
import { InviteEventsInfoDto, LanguageType } from '@/types';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { useMemo, useState } from 'react';

interface Props {
  info: InviteEventsInfoDto | null;
  detail: ActivityDto | null;
  onRefresh: () => void;
}

export const OpenBoxTab = (props: Props) => {
  const { detail } = props;
  const { language } = useLang();

  const [reward, setReward] = useState<{
    title?: string;
    pic?: string;
    num?: number;
    open: boolean;
  }>({
    open: false,
  });
  const [opening, setOpening] = useBoolean();

  const boxList = useMemo(() => {
    return generateBoxesByDetail(detail);
  }, [detail]);

  const handleClickOpen = async (ruleKey: number) => {
    if (!detail?.userActivity) {
      SnackbarUtils.info('Network error, please try again later.');
      return;
    }

    setOpening.on();

    const data = await services.config.openBox({
      activityId: detail?.userActivity?.activityId,
      userActivityId: detail?.userActivity?.id,
      // -1 表示全部开启
      ruleKey,
    });

    setOpening.off();
    // 刷新数据
    props.onRefresh();
    // 显示奖励
    if (Array.isArray(data) && data?.[0]) {
      const reward = data?.[0];
      setReward({
        title:
          language === LanguageType.PT
            ? reward?.prizeName.ptBR
            : reward?.prizeName.enUS,
        pic: reward?.pic,
        num:
          ruleKey === -1
            ? detail.userActivity?.unReceiveTriggers?.length ?? 0
            : 1,
        open: true,
      });
    }
  };

  return (
    <Box>
      <InviteRules info={props.info} />

      <InviteBoxList
        list={boxList}
        inviteCount={
          +(detail?.userActivity?.completeRules?.[0]?.currentValue ?? 0)
        }
        // inviteCount={eventInfo?.vaildInviteeCount ?? 0}
        onOpen={handleClickOpen}
      />

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={opening}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <BoxRewardDialog
        open={reward.open}
        onClose={() => {
          setReward({ open: false });
        }}
        title={reward.title}
        num={reward.num}
        pic={reward.pic}
      />
    </Box>
  );
};

const generateBoxesByDetail = (detail: ActivityDto | null) => {
  if (!detail) {
    return [];
  }

  return (
    detail?.rules?.map((rule) => {
      return {
        money: rule.activityPrizeList[0].prizeValue.value,
        targetInvite: Number(rule.rules[0].value),
        status: Number(rule.ruleState) as BoxStatus,
        ruleKey: rule.ruleKey,
      };
    }) ?? []
  );
};
