import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  ThemeProvider as MUIThemeProvider,
  ThemeOptions,
  createTheme,
} from '@mui/material/styles';
// components
import { useSettingsContext } from '../components/settings';
//
import customShadows from './customShadows';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides'; //引用主题组件
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

type Props = {
  children: React.ReactNode;
};

export const gridSpacing = 3;
export const drawerWidth = 280;
export const appDrawerWidth = 320;
// export const footerHeight = 406;
// export const h5FooterHeight = 200;
export const footerbarHeight = 60;
export const mobileHeaderbarHeight = 48;
export const webHeaderbarHeight = 72;
export const globalMaxWidth = 540;

export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettingsContext();

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette(themeMode),
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows(themeMode),
      customShadows: customShadows(themeMode),
    }),
    [themeDirection, themeMode],
  );

  const theme = createTheme({
    ...themeOptions,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  theme.components = componentsOverride(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </MUIThemeProvider>
  );
}
