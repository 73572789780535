import CommonSkeleton from '@/components/CommonSkeleton';
import EmptyData from '@/components/EmptyData';
import Center from '@/components/center';
import useLang from '@/hooks/useLang';
import { RecordCard } from '@/pages/InviteBoxPage/components/InviteRecordDrawer/RecordCard';
import { inviteEventsInfoState } from '@/stores/invite';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { StatisticsCard } from './StatisticsCard';

interface Props {
  records: any[];
  hasMore: boolean;
  loadMore: () => void;
  loading: boolean;
}

export const OpeningData = (props: Props) => {
  const { records, hasMore, loadMore, loading } = props;
  const { __ } = useLang();
  const eventInfo = useRecoilValue(inviteEventsInfoState);

  if (loading && records.length === 0) {
    return (
      <Box py={4} px={2}>
        <CommonSkeleton />
      </Box>
    );
  }

  return (
    <Box px={2} pt={1} pb={2}>
      <Box mb={1.5}>
        <StatisticsCard />
      </Box>

      {props.records.length === 0 ? (
        <Center py={4}>
          <EmptyData />
        </Center>
      ) : (
        <>
          <Grid container columns={24} spacing={1}>
            {records?.map((record) => (
              <Grid item xs={24} key={record.userId}>
                <RecordCard data={record} info={eventInfo} />
              </Grid>
            ))}
          </Grid>
          <Center mt={2}>
            {hasMore ? (
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={loadMore}
                loading={loading}
                size="small"
                sx={{
                  px: 1.5,
                  py: 1,
                  fontSize: 14,
                  height: 32,
                  borderRadius: '8px',
                  fontWeight: 500,
                }}
              >
                {__('Load More')}
              </LoadingButton>
            ) : (
              <Typography>{__('Nothing more')}</Typography>
            )}
          </Center>
        </>
      )}
    </Box>
  );
};
