import { globalMaxWidth } from '@/theme';
import { IGameCategoryImg, IGameItem } from '@/types/config';
import { hideScrollbarX } from '@/utils/cssStyles';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import EmptyData from '../EmptyData';
import GameFont from '../GameFont';
import Center from '../center';
import ImageWithLoading from '../image';

interface Props {
  title: string;
  desc?: string;
  icon?: IGameCategoryImg;
  bgImg?: IGameCategoryImg;
  list: IGameItem[];
  onClickMore?: () => void;
}
// 首页小卡片样式，一行放6个
export const IconImgBgGameList = (props: Props) => {
  // const isMobile = useResponsive('down', 'md');
  // if (isMobile) {
  // }
  // return <IconImgBgGameListWeb {...props} />;
  return <IconImgBgGameListMobile2 {...props} />;
};

// export const IconImgBgGameListWeb = (props: Props) => {
//   const showList = props.list.slice(0, 8);
//   return (
//     <Box sx={{ position: 'relative', mt: '16px', pt: '24px' }}>
//       {props?.icon?.square && false ? (
//         <Box
//           sx={{
//             position: 'absolute',
//             top: 0,
//             left: '50%',
//             transform: 'translateX(-50%)',
//             width: '40px',
//             display: 'block',
//           }}
//         >
//           <img
//             src={props?.icon?.square}
//             alt=""
//             style={{ width: '48px', height: '48px' }}
//           />
//         </Box>
//       ) : null}
//       <Box
//         sx={{
//           display: 'flex',
//           height: '258px',
//           flexDirection: 'column',
//           justifyContent: 'space-between',
//           backgroundImage: `url(${props?.bgImg?.vertocal || gameBg})`,
//           backgroundSize: 'auto 100%',
//           backgroundPosition: 'center top',
//           backgroundRepeat: 'no-repeat',
//           pb: '12px',
//           px: '12px',
//           borderRadius: '8px',
//         }}
//       >
//         <Center sx={{ mt: '32px' }}>
//           <Typography
//             variant="subtitle1"
//             // className="br-game-font"
//             sx={{
//               fontWeight: '700',
//               px: 1,
//               overflow: 'hidden',
//               width: '100%',
//               textOverflow: 'ellipsis',
//               textAlign: 'center',
//               textShadow: '10px 3px 6px rgba(0, 0, 0, 0.16)',
//               display: 'none',
//             }}
//             className="home-game-list-font"
//           >
//             {props?.title}
//           </Typography>
//         </Center>
//         <Grid container gridRow={1} columns={24} spacing={{ xs: 1.5, sm: 2 }}>
//           {showList.map((item, index) => {
//             return (
//               <Grid item key={item.id} md={3}>
//                 <GameCard item={item} />
//               </Grid>
//             );
//           })}
//         </Grid>
//       </Box>
//     </Box>
//   );
// };

// export const IconImgBgGameListMobile = (props: Props) => {
//   const { __ } = useLang();
//   const navigate = useNavigate();

//   const showList = props.list;

//   return (
//     <Box mb={2}>
//       <HomeSectionContainer hideViewAll title={props.title}>
//         <Stack
//           direction="row"
//           spacing={1}
//           sx={{
//             ...hideScrollbarX,
//           }}
//         >
//           {showList.map((item, index) => {
//             return (
//               <Box
//                 key={index}
//                 sx={{ cursor: 'pointer', position: 'relative' }}
//                 onClick={() => {
//                   navigate('/game/info?id=' + item.id);
//                 }}
//               >
//                 <ImageWithLoading
//                   width={66}
//                   height={88}
//                   aspectRatio={'53/70'}
//                   alt={item.game_name}
//                   src={item.pic}
//                 />
//                 <Center
//                   sx={{
//                     bottom: '3px',
//                     width: '100%',
//                     overflow: 'hidden',
//                     minHeight: '25%',
//                     left: 0,
//                     right: 0,
//                     position: 'absolute',
//                   }}
//                 >
//                   <GameFont name={item.game_name}></GameFont>
//                 </Center>
//               </Box>
//             );
//           })}
//           {props.list.length === 0 && (
//             <Center py={2}>
//               <EmptyData />
//             </Center>
//           )}
//         </Stack>
//       </HomeSectionContainer>
//     </Box>
//   );
// };

export const IconImgBgGameListMobile2 = (props: Props) => {
  const navigate = useNavigate();
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = Math.min(window.innerWidth, globalMaxWidth);
      const totalWidth = windowWidth - (16 * 2 + 4 + 8 + 8 * 4);
      const itemWidth = Math.floor(totalWidth / 4.5);
      setWidth(itemWidth);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const showList = props.list;

  return (
    <Box
      sx={{
        position: 'relative',
      }}
      mb={2}
    >
      <Box
        sx={{
          // bgcolor: 'background.paper',
          background:
            'linear-gradient(109.36deg, #F503D3 13.07%, #3C00FF 50.07%, #00FCA0 87.07%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))',

          // background:
          //   'linear-gradient(90deg, #F503D3 0%, #3C00FF 50%, #00FCA0 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%)',
          borderRadius: '8px',
          px: '2px',
          pb: '2px',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: '700',
            width: '100%',
            textAlign: 'center',
            fontSize: 20,
            fontStyle: 'italic',
            lineHeight: '36px',
            py: 1.5,
          }}
          className="home-game-list-font"
        >
          {props?.title}
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            p: 1,
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            bgcolor: 'background.paper',
            ...hideScrollbarX,
          }}
        >
          {showList.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{ cursor: 'pointer', position: 'relative' }}
                onClick={() => {
                  navigate('/game/info?id=' + item.id);
                }}
              >
                <ImageWithLoading
                  width={width}
                  height={(width * 70) / 53}
                  aspectRatio={'53/70'}
                  alt={item.game_name}
                  src={item.pic}
                />
                <Center
                  sx={{
                    bottom: '3px',
                    width: '100%',
                    overflow: 'hidden',
                    minHeight: '25%',
                    left: 0,
                    right: 0,
                    position: 'absolute',
                  }}
                >
                  {width === 0 ? null : (
                    <GameFont name={item.game_name}></GameFont>
                  )}
                </Center>
              </Box>
            );
          })}
          {props.list.length === 0 && (
            <Center py={2}>
              <EmptyData />
            </Center>
          )}
        </Stack>
      </Box>
    </Box>
  );
};
